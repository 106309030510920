import create from "zustand";
import createVanillaStore from "zustand/vanilla";
import type { UserState } from "types/user";

const signOut = (location = "/signin") => {
  localStorage.removeItem("userPermissions");

  const signOutUrl = `/api/logout?${new URLSearchParams({
    location,
  }).toString()}`;

  document.location.href = signOutUrl;
};

const defaultUser: UserState = {
  loading: true,
  isLoggedIn: false,
  details: {},
  access: null,
  signOut,
};

interface UserStoreState {
  user: UserState;
  updateUser: (value: Partial<UserState>) => void;
  clearUser: () => void;
}

export const userController = createVanillaStore<UserStoreState>((set) => ({
  user: defaultUser,
  updateUser: (value) => {
    set((prev) => ({ user: { ...prev.user, ...value } }));
  },
  clearUser: () => {
    set(() => ({ user: { ...defaultUser, loading: false } }));
  },
}));

const useUserStore = create(userController);

export default useUserStore;
