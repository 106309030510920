import create from "zustand";
import createVanillaStore from "zustand/vanilla";

interface DrawerState {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

const drawerController = createVanillaStore<DrawerState>((set) => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
}));

export const useAppDrawer = create(drawerController);

export default drawerController;
