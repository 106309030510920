import type { ReactNode } from "react";
import { createContext, useContext, useMemo } from "react";
import Script from "next/script";

type ConversionType = "FREE_CONVERSION" | "SELECT_CONVERSION";

const sendToMap: Record<ConversionType, string> = {
  FREE_CONVERSION: "AW-1040492980/1bLtCIqkuv4BELTTkvAD",
  SELECT_CONVERSION: "AW-1040492980/18DmCP7tsf4BELTTkvAD",
};

/**
 * Log a Google Tag Manager event `"conversion"` which is created when a user either signs up or upgrades their account to select.
 *
 * @param type - The type of convertion to report, either `"FREE_CONVERSION"` or `"SELECT_CONVERSION"`
 * @returns A promise which resolves when the event submission resolves
 */
const reportConversion = (
  type: ConversionType = "FREE_CONVERSION"
): Promise<void> =>
  new Promise<void>((resolve) => {
    if (process.env.APP_ENV === "development") {
      resolve();
    }

    try {
      window.gtag("event", "conversion", {
        send_to: sendToMap[type],
        event_callback: () => resolve(),
      });
    } catch {
      resolve();
    }
  });

interface GtagContextValue {
  reportConversion: typeof reportConversion;
}

const GtagContext = createContext<GtagContextValue>({ reportConversion });

export const useGtag = () => useContext(GtagContext);

interface GtagProviderProps {
  children: ReactNode;
}

export const GtagProvider = ({ children }: GtagProviderProps) => {
  const contextValue = useMemo(() => ({ reportConversion }), []);

  return (
    <GtagContext.Provider value={contextValue}>
      {/* Initialize Google Tag Manager */}
      {process.env.NODE_ENV === "production" && (
        <>
          <Script
            id="gtag-init"
            src="https://www.googletagmanager.com/gtag/js?id=G-2ZZXBCKWGV"
          />
          <Script id="gtag">
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){window.dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-2ZZXBCKWGV');
            `}
          </Script>
        </>
      )}

      {children}
    </GtagContext.Provider>
  );
};

export default GtagContext;
