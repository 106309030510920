const card = {
  bg: "white",
  boxShadow: "md",
  rounded: "lg",
  p: "4",
};

const layerStyles = {
  card,
  borderCard: {
    ...card,
    borderLeft: "4px solid",
  },
};

export default layerStyles;
