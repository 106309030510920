import { theme as defaultTheme, defineStyleConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

export const Button = defineStyleConfig({
  baseStyle: {
    flexShrink: 0,
  },
  sizes: {
    md: {
      rounded: "lg",
    },
  },
  variants: {
    "outline-solid": (props) => {
      const { colorScheme: c } = props;
      const borderColor = mode(`gray.200`, `whiteAlpha.300`)(props);
      return {
        border: "1px solid",
        borderColor: c === "gray" ? borderColor : `${c}.500`,
        ".chakra-button__group[data-attached] > &:not(:last-of-type)": {
          marginEnd: "-1px",
        },
        ...defaultTheme.components.Button.variants.ghost(props),
        bg: props.isChecked ? `${c}.500` : "transparent",
        color: props.isChecked ? "white" : `${c}.500`,
        _hover: {
          bg: props.isChecked ? `${c}.600` : `${c}.100`,
          color: props.isChecked ? "white" : `${c}.500`,
        },
      };
    },
  },
});

export const Input = defineStyleConfig({
  sizes: {
    sm: {
      field: {
        rounded: "base",
      },
    },
  },
});

export const Tag = defineStyleConfig({
  baseStyle: {
    label: {
      display: "inline-block",
    },
  },
});

const components = {
  Button,
  Input,
  Tag,
};

export default components;
