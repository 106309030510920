import { extendTheme } from "@chakra-ui/react";
import components from "./component-styles";
import globalStyles from "./global-styles";
import layerStyles from "./layer-styles";
import colors from "./tailwind-colors";
import textStyles from "./text-styles";

const theme = extendTheme({
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  styles: {
    global: globalStyles,
  },
  components,
  colors,
  fonts: {
    body: "Inter, sans-serif",
    heading: "Inter, sans-serif",
    mono: "Menlo, monospace",
  },
  layerStyles,
  textStyles,
  shadows: {
    "outline-light": "0 0 0 3px rgba(147, 197, 253, 0.6)",
  },
});

export default theme;
