var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"d74ca7562c3c7372e235578c61b2c0b41e4d33c3"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from "@sentry/nextjs";
import { Integrations as TracingIntegrations } from "@sentry/tracing";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://e68cae27bbe1452e84cf64855d4ea561@o851747.ingest.sentry.io/5818609",

  environment: process.env.APP_ENV,
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps

  // This enables automatic instrumentation (highly recommended), but is not
  // necessary for purely manual usage
  integrations: [new TracingIntegrations.BrowserTracing()],
  whitelistUrls: [/privco\.com/],
  ignoreErrors: [
    // This is a generic error for the browser drops a frame in a ResizeObserver.
    // Doesn't actually break anything nor is it noticeable
    "ResizeObserver loop limit exceeded",
    // Ignore when the map element disappears before it can be painted on the canvas
    "Non-Error promise rejection captured with keys: currentTarget, isTrusted, target, type",
  ],
  // To set a uniform sample rate
  tracesSampleRate: 0.2,
});
