/* eslint-disable no-console */

/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useCallback } from "react";

const waitForIntercomLoad = () =>
  new Promise<void>((resolve) => {
    const interval = setInterval(() => {
      const keys = window.Intercom && Object.keys(window.Intercom);
      if (
        typeof window.Intercom === "function" &&
        keys.includes("booted") &&
        keys.length === 1
      ) {
        resolve();
        clearInterval(interval);
      }
    }, 2);
  });

const startIntercom = () => {
  window.intercomSettings = {
    app_id: "d0940yd8",
    hide_default_launcher: true,
  };

  try {
    const w = window;
    const ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      const d = document;
      const i = (...args: any[]) => {
        i.c(args);
      };
      // @ts-ignore
      i.q = [];
      i.c = (args: any) => {
        i.q.push(args);
      };
      w.Intercom = i;
      const l = () => {
        const s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.intercom.io/widget/d0940yd8";
        const x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      };

      l();
    }
  } catch (error) {
    console.error(error);
  }
};

interface UpdateIntercomData {
  id: number;
  name: string;
  email: string;
  group: string;
  intercom: string;
}

const shouldHideIntercomBot = () => true;
// const validAccountTypes = ["free", "consumer", "select"];
// if (validAccountTypes.includes(group)) {
//   return false;
// }

// return true;
const useIntercom = () => {
  const updateIntercom = useCallback(async (data: UpdateIntercomData) => {
    await waitForIntercomLoad();
    window.Intercom("update", {
      hide_default_launcher: shouldHideIntercomBot(), // data.group
      email: data.email,
      name: data.name,
      "Plan type": data.group,
      user_id: data.id,
      user_hash: data.intercom,
    });
  }, []);
  return { updateIntercom };
};

export { startIntercom, useIntercom };
