import { useEffect } from "react";
import type { ReactElement, ReactNode } from "react";
import type { AppProps } from "next/app";
import dynamic from "next/dynamic";
import Router from "next/router";
import { ChakraProvider } from "@chakra-ui/react";
import type { NextPage } from "next";
import { DefaultSeo } from "next-seo";
import { SWRConfig } from "swr";
import drawerController from "stores/use-app-drawer";
import { GtagProvider } from "components/app/gtag-context";
import { startHeap } from "hooks/use-heap";
import { startIntercom } from "hooks/use-intercom";
import fetcher from "utils/fetcher";
import theme from "styles/theme";

const AppProgressBar = dynamic(
  () => import("components/app/app-progress-bar"),
  { ssr: false }
);

const setupAxe = async () => {
  const React = (await import("react")).default;
  const ReactDOM = (await import("react-dom")).default;
  // eslint-disable-next-line import/no-extraneous-dependencies
  const axe = (await import("@axe-core/react")).default;
  axe(React, ReactDOM, 1000);
};

const { getState } = drawerController;

Router.events.on("routeChangeStart", (destination) => {
  getState().onClose();
  if (
    process.env.APP_ENV === "production" &&
    window.location.hostname === "www.privco.com" &&
    (destination.startsWith("/signin") || destination.startsWith("/signup"))
  ) {
    window.location.assign(
      `https://system.privco.com${window.location.pathname}${window.location.search}`
    );
  }
});

if (typeof window !== "undefined") {
  if (process.env.NODE_ENV !== "production") {
    setupAxe();
  }
}

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const MyApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  useEffect(() => {
    const onload = () => {
      startIntercom();
      startHeap();
    };

    window.addEventListener("load", onload);

    return () => {
      window.removeEventListener("load", onload);
    };
  }, []);

  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <>
      <DefaultSeo
        defaultTitle="PrivCo | Private Company Financial Database"
        titleTemplate="%s | PrivCo"
        description="PrivCo is the go-to-source for valuable insights into the financial health, market position and trajectory of U.S. private companies."
        twitter={{
          handle: "@PrivCo",
          site: "@PrivCo",
          cardType: "summary_large_image",
        }}
        openGraph={{
          type: "website",
          title: "PrivCo | Private Company Financial Database",
          locale: "en_US",
          description:
            "PrivCo is the go-to-source for valuable insights into the financial health, market position and trajectory of U.S. private companies.",
          images: [
            {
              url: "https://system.privco.com/cover-image.png",
              width: 1200,
              height: 627,
              alt: "PrivCo Logo Banner",
            },
          ],
          site_name: "PrivCo",
        }}
      />
      <GtagProvider>
        <SWRConfig value={{ fetcher }}>
          <ChakraProvider theme={theme}>
            <AppProgressBar />
            {getLayout(<Component {...pageProps} />)}
          </ChakraProvider>
        </SWRConfig>
      </GtagProvider>
    </>
  );
};

export default MyApp;
