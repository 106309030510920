/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from "react";

const waitForHeapLoad = () =>
  new Promise<void>((resolve) => {
    const interval = setInterval(() => {
      if (typeof window.heap === "object" && window.heap.loaded) {
        resolve();
        clearInterval(interval);
      }
    }, 2);
  });

const startHeap = (): void => {
  window.heap = window.heap || [];

  if (window?.heap?.loaded) {
    return;
  }

  window.heap.load = (appId: string, heapConfig?: Record<string, unknown>) => {
    window.heap.appid = appId;
    window.heap.config = heapConfig || {};

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = `https://cdn.heapanalytics.com/js/heap-${appId}.js`;

    const firstScript = document.getElementsByTagName("script")[0];
    firstScript.parentNode.insertBefore(script, firstScript);

    const cloneArray = (arrayLike: any[]) =>
      Array.prototype.slice.call(arrayLike, 0);

    const createMethod =
      (method: string) =>
      (...args: any[]) => {
        window.heap.push([method, ...cloneArray(args)]);
      };

    const methods = [
      "addEventProperties",
      "addUserProperties",
      "clearEventProperties",
      "identify",
      "resetIdentity",
      "removeEventProperty",
      "setEventProperties",
      "track",
      "unsetEventProperty",
    ];

    methods.forEach((method) => {
      window.heap[method] = createMethod(method);
    });
  };

  window.heap.load(process.env.NEXT_PUBLIC_HEAP_ID);
};

interface HeapUser {
  username?: string;
  group?: string;
  id?: number;
  directAccess?: boolean;
  intercom?: string;
}

const useHeap = (): { updateHeap: (data: HeapUser) => Promise<void> } => {
  const updateHeap = useCallback(async (data: HeapUser) => {
    await waitForHeapLoad();
    window.heap.identify(data.username);
    window.heap.addUserProperties({
      username: data.username,
      email: data.username,
      group: data.group,
      id: data.id,
      hasDirectAcces: data.directAccess,
    });
  }, []);

  return { updateHeap };
};

export { startHeap, useHeap };
