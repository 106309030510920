/* eslint-disable deprecation/deprecation */
import tailwindColors from "tailwindcss/colors";

// Remove extra color props
delete tailwindColors.inherit;
delete tailwindColors.transparent;
delete tailwindColors.current;

// Remove deprecated colors
delete tailwindColors.lightBlue;
delete tailwindColors.warmGray;
delete tailwindColors.trueGray;
delete tailwindColors.coolGray;
delete tailwindColors.blueGray;

export default tailwindColors;
