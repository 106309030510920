import * as Sentry from "@sentry/nextjs";
import Cookies from "js-cookie";
import { userController } from "stores/use-user-store";

const isServer = typeof window === "undefined";

const isOnRootDomain = () =>
  !isServer &&
  process.env.APP_ENV === "production" &&
  window.location.hostname === "www.privco.com";

const getRootUrl = () =>
  isOnRootDomain() || isServer
    ? `https://${process.env.NEXT_PUBLIC_DOMAIN}`
    : "";

const getResponse = async (
  res: Response
): Promise<Record<string, unknown> | string | Response> => {
  const contentType = res.headers.get("Content-Type")?.toLowerCase();
  if (contentType && /application\/[a-z+]*json/.test(contentType)) {
    return res.json();
  }

  if (contentType && /text\/plain*/.test(contentType)) {
    return res.text();
  }

  return res;
};

class FetcherError extends Error {
  info?: Record<string, unknown>;

  status?: number;

  constructor(message: string, info = {}) {
    super(message);
    this.name = "FetcherError";
    this.info = info;
  }
}

const fetcher = async <ResponseInterface>(
  url: RequestInfo,
  options: RequestInit = {},
  useSystemUrl = true
): Promise<ResponseInterface> => {
  const res = await fetch(
    useSystemUrl ? `${getRootUrl()}${url}` : url,
    options
  );

  const { clearUser } = userController.getState();

  if (!res.ok) {
    const error = new FetcherError(
      "An error occurred while fetching the data."
    );

    try {
      error.info = await res.json();

      let errMsg = error.info?.message as string;
      errMsg = errMsg?.toLocaleLowerCase();

      if (errMsg === "user missing" || errMsg === "expired token") {
        clearUser();
        Cookies.remove("payload");
        localStorage.removeItem("userPermissions");
      }
    } catch (err) {
      error.info = {};
    }

    error.status = res.status;

    Sentry.addBreadcrumb({
      category: "fetch",
      message: `attempted to fetch: ${url}`,
      level: "error",
      data: error,
    });

    if (typeof window !== "undefined") {
      Sentry.captureException(error);
    }

    throw error;
  }

  const out = (await getResponse(res)) as ResponseInterface;

  return out;
};

export const post = async <RequestInterface, ResponseInterface>(
  url: RequestInfo,
  body?: RequestInterface,
  options: RequestInit = {}
): Promise<ResponseInterface> => {
  const resp = await fetcher<ResponseInterface>(url, {
    ...options,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...options?.headers,
    },
    ...(body && { body: JSON.stringify(body) }),
  });

  return resp;
};

export const put = async <RequestInterface, ResponseInterface>(
  url: RequestInfo,
  body?: RequestInterface,
  options: RequestInit = {}
): Promise<ResponseInterface> => {
  const resp = await fetcher<ResponseInterface>(url, {
    ...options,
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...options?.headers,
    },
    ...(body && { body: JSON.stringify(body) }),
  });

  return resp;
};

export const deleteRequest = async <RequestInterface, ResponseInterface>(
  url: RequestInfo,
  body?: RequestInterface,
  options: RequestInit = {}
): Promise<ResponseInterface> => {
  const resp = await fetcher<ResponseInterface>(url, {
    ...options,
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      ...options?.headers,
    },
    ...(body && { body: JSON.stringify(body) }),
  });

  return resp;
};

export const patch = async <RequestInterface, ResponseInterface>(
  url: RequestInfo,
  body?: RequestInterface,
  options: RequestInit = {}
): Promise<ResponseInterface> => {
  const resp = await fetcher<ResponseInterface>(url, {
    ...options,
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      ...options?.headers,
    },
    ...(body && { body: JSON.stringify(body) }),
  });

  return resp;
};

export default fetcher;

export const delay = async (millis = 1000) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, millis);
  });
