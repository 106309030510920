const textStyles = {
  cardHeader: {
    fontWeight: "bold",
    mb: "4",
    color: "slate.700",
    size: "sm",
    borderBottom: "1px solid",
    borderColor: "slate.200",
    pb: "3",
  },
};

export default textStyles;
