const globalStyles = {
  "html, body, #__next": {
    height: "100%",
    width: "100%",
    overflow: "hidden",
  },
  ".mapboxgl-ctrl-attrib-button": {
    display: "none",
  },
  ".card-table-body .card-tr:hover td": {
    bg: "blue.50",
  },
  "input[type='search']": {
    "&[placeholder], &::placeholder": {
      textOverflow: "ellipsis",
    },
    "&::-webkit-search-decoration": {
      WebkitAppearance: "none",
    },
    "&::-webkit-search-results-button": {
      WebkitAppearance: "none",
    },
    "&::-webkit-search-results-decoration": {
      WebkitAppearance: "none",
    },
    "&::-webkit-search-cancel-button": {
      WebkitAppearance: "none",
      cursor: "pointer",
      height: "14px",
      width: "14px",
      backgroundImage: "url('/icons/clear-input-icon.svg')",
    },
  },
};

export default globalStyles;
